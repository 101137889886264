import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import TextScroller from "../components/TextScroller";
import ColoredLine from "../components/ColoredLine";
import SearchBar from "../components/SearchBar";
import P230819 from "./P230819";
import P230824 from "./P230824";
import P230825 from "./P230825";
import P230920 from "./P230920";
import P230920_2 from './P230920_2';

function Landing() {
  const searchInputRef = useRef(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const dates = ["230819.zip", "230824.zip"];

  const handleClick = (e) => {
    e.preventDefault();
    setShowSearchBar(true);
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      const searchTerm = searchInputRef.current.value;
      if (dates.includes(searchTerm)) {
        const targetElement = document.getElementById(searchTerm);
        if (targetElement) {
          window.scrollTo({
            behavior: 'smooth',
            top: targetElement.offsetTop,
          });
        } else {
          alert(`Page '${searchTerm}' not found.`);
        }
      } else {
        alert(`Page ${searchTerm} not found`);
      }
      setShowSearchBar(false);
    }
  };
  return (
    <section>
      <Container fluid className="landing-section" id="landing">
        <Container className="home-content">
          {showSearchBar ? 
            (<div>
              <input
                type="search"
                placeholder="Search here"
                ref={searchInputRef}
                onKeyPress={handleSearch}
                style={{
                  color: '#1e1e1e',
                  fontSize: '2.5em',
                  border: 'none',
                  outline: 'none'
                }}
              />
            </div> ):
            (<div onClick={handleClick}>
              <Typewriter
                options={{
                  strings: [
                    "yymmdd.zip"
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 20,
                }}
              />
            </div>)
          }
          <ColoredLine color="#1e1e1e" vertical={false}/>
        </Container>
      </Container>
    <div id="230920_2.zip"><P230920_2/></div>
    <div id="230920.zip"><P230920/></div>
    {/* <div id="230825.zip"><P230825/></div> */}
    <div id="230824.zip"><P230824/></div>
    <div id="230819.zip"><P230819 id="230819"/></div>
    <a href="/privacy">View privacy policy</a>
    </section>
  );
}

export default Landing;
