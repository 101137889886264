import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {isMobile} from 'react-device-detect';
import VideoOnHover from "../components/VideoOnHover";

import photo_1 from "../assets/230819_1.JPG";
import photo_2 from "../assets/230819_2.JPG";
import photo_3 from "../assets/230819_3.JPG";
import photo_4 from "../assets/230819_4.JPG";
import photo_5 from "../assets/230819_5.JPG";
import thumbnail from "../assets/230819_vid.png";
import video from "../assets/230819_video.MP4";
import lettering from "../assets/lettering.png";


function P230819() {
  if(isMobile) {
    return (
      <Container fluid className="home-section">
          <div className="home-column-right">
            <img
              src={lettering}
              alt="lettering"
              className="image-stack__item--overlay"
            />
            <VideoOnHover
              img_src={thumbnail}
              img_alt="230819"
              video_src={video}
              video_alt="230819"
            />
            <img
              src={photo_2}
              alt="photo 2"
              className="image-stack__item--middle"
            />
            <img
              src={photo_4}
              alt="photo 4"
              className="image-stack__item--bottom"
            />
          </div>
          <div className="home-column">
            <div className="stacked-photos">
              <img
                  src={photo_1}
                  alt="photo 1"
                  className="img-horizontal"
                />
                <img
                  src={photo_3}
                  alt="photo 1"
                  className="img-horizontal"
                />
            </div>
          </div>
          <h2 className="main-caption" style={{fontSize: "1em"}}>230819.zip</h2>
      </Container>
    );
  }
  return (
    <Container fluid className="home-section">
        <div className="home-column-right">
          <img
            src={lettering}
            alt="lettering"
            className="image-stack__item--overlay"
          />
          <VideoOnHover
            img_src={thumbnail}
            img_alt="230819"
            video_src={video}
            video_alt="230819"
          />
          <img
            src={photo_2}
            alt="photo 2"
            className="image-stack__item--middle"
          />
          <img
            src={photo_4}
            alt="photo 4"
            className="image-stack__item--bottom"
          />
        </div>
        <div className="home-column">
          <div className="stacked-photos">
            <img
                src={photo_1}
                alt="photo 1"
                className="img-horizontal"
              />
              <img
                src={photo_3}
                alt="photo 1"
                className="img-horizontal"
              />
          </div>
        </div>
        <h2 className="main-caption">230819.zip</h2>
    </Container>
  );
}

export default P230819;
