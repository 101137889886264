import React, { useState, useEffect } from 'react';

const StopMotionGallery = ({path}) => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const importImages = async () => {
      try {
          const new_path = path ? `../assets/${path}` : '';
          const imageContext = require.context(`${new_path}`, false, /\.(png|jpe?g|JPG)$/);
          const imagePromises = imageContext.keys().map(async (key) => {
            const module = await import(`../assets/${path}${key.substr(1)}`);
            return module.default;
        });
        const loadedImages = await Promise.all(imagePromises);
        console.log(loadedImages)
        setImages(loadedImages);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    importImages();
  }, []);
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500); // Adjust the delay (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [currentIndex, images]);

  return (
    <div className="stop-motion-gallery">
      {images.map((image, index) => (
        <img
          key={index}
          src={image} // Adjust the path as needed
          alt={`Photo ${index + 1}`}
          className='stop-motion-img'
          style={{ display: index === currentIndex ? 'block' : 'none' }}
        />
      ))}
      
    </div>
  );
};

export default StopMotionGallery;
