import React from 'react';

const VideoOnHover = (props) => {
  return (
    <div className="video-container">
      <img
        src={props.img_src}
        alt={props.img_alt}
        className='hover-image'
      />
      <video
        src={props.video_src}
        alt={props.video_alt}
        className="hover-video"
        autoPlay
        loop
        muted
      />
    </div>
  );
};

export default VideoOnHover;
