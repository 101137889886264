import React from 'react';
import { isMobile } from 'react-device-detect';
import { Container } from "react-bootstrap";
import { Grid } from '@mui/material';
import image_1 from '../assets/ploy_lib/1.jpg';
import image_2 from '../assets/ploy_lib/3.jpg';
import image_3 from '../assets/ploy_lib/4.jpg';
import image_4 from '../assets/ploy_lib/5.jpg';
import image_5 from '../assets/ploy_lib/6.jpg';
import image_6 from '../assets/ploy_lib/7.jpg';
import image_7 from '../assets/ploy_lib/8.jpg';
import image_8 from '../assets/ploy_lib/9.jpg';
import image_9 from '../assets/ploy_lib/10.jpg';
import image_10 from '../assets/ploy_lib/11.jpg';
import image_11 from '../assets/ploy_lib/12.jpg';
import main from '../assets/ploy_lib/main.jpg';

function P230920_2() {
    if(isMobile){
    
    }
    const images = [image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8. image_9, image_10, image_11]
    return (
        <Container fluid className="container-section" style={{paddingBottom: 25}}>
                <div className='left-column'>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {Array.from(images).map((_, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <img src={images[index]} className='img-icon'></img>
                            </Grid>
                        ))}
                    </Grid>
                </div>   
                <div className='right-column' style={{alignItems: 'right'}}>
                    <img src={main} style={{maxWidth: '50vw'}}></img>
                </div>  
                {/* <h2>230920.zip</h2>        */}
            {/* <div className="main-caption" style={{paddingLeft: 0}}>
                
            </div> */}
        </Container>
    )
}

export default P230920_2;