import React from 'react';
import {isMobile} from 'react-device-detect';
import { Container } from "react-bootstrap";
import { Grid, GridItem } from '@chakra-ui/react';
import StopMotionGallery from '../components/StopMotionGallery';

function P230920() {
    if(isMobile){
    
    }
    return (
        <Container fluid className="container-section" style={{paddingBottom: 25}}>
            <StopMotionGallery path="ploy"/>
            {/* <h2 className="main-caption">230920.zip</h2> */}
        </Container>
    )
}

export default P230920;