import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import TextScroller from "../../components/TextScroller";
import ColoredLine from "../../components/ColoredLine";
import image1 from "./neighborhood1.JPG";

function Privacy() {
  const searchInputRef = useRef(null);
  // const mobilenet = require('@tensorflow-models/mobilenet');

  // async function img_gen() {
  //   // const img = document.getElementById('img');
  //   const img = image1;
  //   // Load the model.
  //   const model = await mobilenet.load();

  //   // Classify the image.
  //   const predictions = await model.classify(img);
  //       console.log('Predictions: ');
  //       console.log(predictions);
  // }
  // img_gen();
  return (
    <section>
      <Container fluid className="landing-section" id="landing">
        <Container className="home-content">
          <ColoredLine color="#1e1e1e" vertical={false}/>
          <h1>Privacy Policy</h1>
          Privacy Policy
Introduction
yymmdd.zip ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website yymmdd.zip, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.

Information We Collect
We may collect information about you in a variety of ways. The information we may collect on the Site includes:

Personal Data

Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind, however, your refusal to do so may prevent you from using certain features of the Site.

Derivative Data

Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.


Use of Your Information
Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:


Disclosure of Your Information
We may share information we have collected about you in certain situations. Your information may be disclosed as follows:

By Law or to Protect Rights

If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.

Third-Party Service Providers

We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.

Business Transfers

We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

Security of Your Information
We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.

Policy for Children
We do not knowingly solicit information from or market to children under the age of 13. If we learn we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at [your contact information].

Changes to This Privacy Policy
We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by updating the "Last Updated" date of this Privacy Policy. You are encouraged to review this Privacy Policy periodically to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.

Contact Us
If you have questions or comments about this Privacy Policy, please contact us at:

yymmdd.zip
        </Container>
      </Container>
    </section>
  );
  
}

export default Privacy;
