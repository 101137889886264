import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import VideoOnHover from "../components/VideoOnHover";
import photo_1 from "../assets/230824/230824_1.png";
import photo_2 from "../assets/230824/230824_2.png";
import photo_3 from "../assets/230824/230824_3.png";
import { isMobile } from "react-device-detect";


function P230824() {
  if(isMobile){
    return (
      <Container fluid className="container-section">
        <div className="right-column">
          <img
            src={photo_1}
            alt="photo 1"
            className="img-vertical"
          />
        </div>
        <div className="left-column">
          <div className="stacked-images">
            <img
              src={photo_2}
              alt="photo 2"
              className="img-horizontal"
            />
            <img
              src={photo_3}
              alt="photo 3"
              className="img-horizontal"
            />
          </div>
        </div>
        <h2 style={{fontSize: "1em"}} className="main-caption">230824.zip</h2>
    </Container>
    )
  }
  return (
    <Container fluid className="home-section">
        <div className="right-column">
          <img
            src={photo_1}
            alt="photo 1"
            className="img-vertical"
          />
        </div>
        <div className="left-column">
          <div className="stacked-images">
            <img
              src={photo_2}
              alt="photo 2"
              className="img-horizontal"
            />
            <img
              src={photo_3}
              alt="photo 3"
              className="img-horizontal"
            />
          </div>
        </div>
        <h2 className="main-caption">230824.zip</h2>
    </Container>
  );
}

export default P230824;
