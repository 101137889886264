import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
// import "./global.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App";
// import theme from "./theme/index"
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();