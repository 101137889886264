import React from 'react';

const ColoredLine = ({ color, vertical }) => (
    !vertical ? 
    (<hr
        style={{
            color: color,
            // backgroundColor: color,
            height: "1px",
            borderRadius: 0,
            opacity: 100,
        }}
    />
    ) :
    <hr 
        style={{
            width: "1px",
            height: "35px",
            borderLeft: "1px solid black",
            display: "inline-block",
            color: color,
            opacity: 100
        }}
    />
    
);

export default ColoredLine;